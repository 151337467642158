import React from 'react'
import Layout from '../components/layout'
import Shopify from '../components/categories/shopify'
import Seo from '../components/seo'

const ShopifyPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Shopify />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Shopify Freelancers | Codestaff'
  const description =
    'Hire the best Shopify freelancers at Codestaff. Get the top 1% of Shopify professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ShopifyPage
